import { sendApiRequest } from '@/api/sendApiRequest';
import { httpMethods } from '@/api';

import {
  TBillingAccountCreateRequest,
  TBillingAvailablePlansResponse,
  TBillingSubscriptionsLimitsResponse,
  TBillingTransactionsInfoResponse,
  TInvoiceCreateRequest,
  TWalletResponse,
} from '../domain/types';
import { EBillingPlanCode } from '../domain/constants';

export const createBillingAccount = (payload: TBillingAccountCreateRequest) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/billing/account',
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const updatePlan = (payload: TBillingAccountCreateRequest) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/subscription_upgrades',
  payload,
});

export const requestConfirmationCode = (invoicingEmail: string) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/billing/invoicing_email_confirmations/request_code',
  payload: { invoicingEmail },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const confirmCodeForEmail = (invoicingEmail: string, code: string) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/billing/invoicing_email_confirmations/confirm_code',
  payload: {
    invoicingEmail,
    code,
  },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getSubscriptionsLimit = (
  planCode: EBillingPlanCode,
) => sendApiRequest<TBillingSubscriptionsLimitsResponse>({
  method: httpMethods.GET,
  params: { planCode },
  endpoint: '/:tenant_id/subscriptions/limits',
});

export const getBillingTransactionsInfo = () => sendApiRequest<TBillingTransactionsInfoResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/transactions/info',
});

export const cancelPlanUpdate = (planCode: EBillingPlanCode) => sendApiRequest({
  method: httpMethods.DELETE,
  endpoint: '/:tenant_id/subscription_upgrades',
  params: { planCode },
});

export const getAvailablePlans = () => sendApiRequest<TBillingAvailablePlansResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/subscriptions/available_plans',
});

/** Фиксирование факта ознакомления со спец. условиями тарифа */
export const confirmReadingSpecialOffer = () => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/special_offers/confirm',
});

export const getWallet = () => sendApiRequest<TWalletResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/wallet',
});

export const createPaymentInvoice = (payload: TInvoiceCreateRequest) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/wallet/top_up',
  payload,
});
